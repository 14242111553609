import { Dom } from './Dom';
import { UserAgent } from './UserAgent';
export class DOMPosition {
    constructor() {
        this.height = 0;
        this.left = 0;
        this.top = 0;
        this.width = 0;
    }
}
export class DOMHelper {
    static _nodeStyle(node, style, extra) {
        if (typeof style === 'object') {
            for (const i in style) {
                const key = i;
                const cssProperty = i.replace(/([A-Z])/, '-$1').toLowerCase();
                node.style.setProperty(cssProperty, String(style[key]));
            }
        }
        else if (typeof extra == 'string' || typeof extra == 'number') {
            node.style[style] = String(extra);
        }
        else if (style in node.style) {
            return node.style[style];
        }
    }
    static addClass(node, cname) {
        const cnames = DOMHelper.getClassNames(node);
        cnames[cname] = cname;
        node.className = DOMHelper.canonicalizeMap(cnames);
    }
    static get dom() {
        return new Dom();
    }
    static byId(domId) {
        return this.dom.byId(domId);
    }
    static canonicalizeMap(map) {
        const cnames = [];
        for (const x in map) {
            cnames.push(x);
        }
        return cnames.join(' ');
    }
    static coords(node, _hva) {
        const bbox = node.getBoundingClientRect();
        return {
            l: node.clientLeft,
            t: node.clientTop,
            h: node.clientHeight,
            w: node.clientWidth,
            x: bbox.left + window.scrollX,
            y: bbox.top + window.scrollY,
        };
    }
    static frames(window) {
        return window.frames;
    }
    // Retrieve the position of an object's center point
    // =================================================
    static getCenter(domElementOrId) {
        const position = this.getPosition(domElementOrId);
        if (position == null) {
            return null;
        }
        position.left = position.left + position.width / 2;
        position.top = position.top + position.height / 2;
        return position;
    }
    static getClassNames(node) {
        const classNameMap = {};
        for (const i of node.className.split(' ')) {
            classNameMap[i] = i;
        }
        return classNameMap;
    }
    static getContentWindow(el) {
        if ('contentWindow' in el) {
            return el.contentWindow;
        }
        throw new Error('Cant find content window');
    }
    static getCorrectVisibleDisplay(node) {
        switch (node.tagName) {
            case 'SPAN':
                return 'inline';
            case 'TBODY':
                if (new UserAgent().isIE) {
                    return 'block';
                }
                return 'table-row-group';
            case 'TR':
                if (new UserAgent().isIE) {
                    return 'block';
                }
                return 'table-row';
            case 'LI':
                return 'list-item';
            default:
                return 'block';
        }
    }
    static getEventTargetElement(event) {
        const any_event = event;
        if ('target' in any_event && any_event.target instanceof HTMLElement) {
            return any_event.target;
        }
        if ('currentTarget' in any_event && any_event.currentTarget instanceof HTMLElement) {
            return any_event.currentTarget;
        }
        if ('scrElement' in any_event && any_event.srcElement instanceof HTMLElement) {
            return any_event.srcElement;
        }
        return null;
    }
    // Retrieve the position and size of an object
    // ===========================================
    static getPosition(n) {
        const fixBrowserQuirks = true;
        // If a string is passed in instead of an object ref, resolve it
        let o = null;
        if (typeof n === 'string') {
            o = this.resolveElement(n);
        }
        else if (n instanceof HTMLElement) {
            o = n;
        }
        if (o == null) {
            return null;
        }
        let left = 0;
        let top = 0;
        let width = 0;
        let height = 0;
        let offsetParent = null;
        offsetParent = o.offsetParent;
        const originalObject = o;
        let el = o; // "el" will be nodes as we walk up, "o" will be saved for offsetParent references
        while (el && el.parentNode != null) {
            el = el.parentNode instanceof HTMLElement ? el.parentNode : null;
            if (!el) {
                break;
            }
            if (el.offsetParent == null) {
                /* void */
            }
            else {
                let considerScroll = true;
                /*
                In Opera, if parentNode of the first object is scrollable, then offsetLeft/offsetTop already
                take its scroll position into account. If elements further up the chain are scrollable, their
                scroll offsets still need to be added in. And for some reason, TR nodes have a scrolltop value
                which must be ignored.
                */
                if (fixBrowserQuirks && 'opera' in window) {
                    if (el === originalObject.parentNode || el.nodeName === 'TR') {
                        considerScroll = false;
                    }
                }
                if (considerScroll) {
                    if (el.scrollTop && el.scrollTop > 0) {
                        top -= el.scrollTop;
                    }
                    if (el.scrollLeft && el.scrollLeft > 0) {
                        left -= el.scrollLeft;
                    }
                }
            }
            // If this node is also the offsetParent, add on the offsets and reset to the new offsetParent
            if (el === offsetParent) {
                left += o.offsetLeft;
                if (el.clientLeft && el.nodeName !== 'TABLE') {
                    left += el.clientLeft;
                }
                top += o.offsetTop;
                if (el.clientTop && el.nodeName !== 'TABLE') {
                    top += el.clientTop;
                }
                o = el;
                if (o.offsetParent == null) {
                    if (o.offsetLeft) {
                        left += o.offsetLeft;
                    }
                    if (o.offsetTop) {
                        top += o.offsetTop;
                    }
                }
                offsetParent = o.offsetParent;
            }
        }
        if (originalObject.offsetWidth) {
            width = originalObject.offsetWidth;
        }
        if (originalObject.offsetHeight) {
            height = originalObject.offsetHeight;
        }
        return {
            left,
            top,
            width,
            height,
        };
    }
    static htmlEntities(str) {
        if (str === null || str === undefined) {
            return '';
        }
        const node = document.createElement('span');
        const txt = document.createTextNode(str);
        node.appendChild(txt);
        const enc = node.innerHTML;
        return enc;
    }
    static query(selector, element) {
        let hits;
        if (element) {
            hits = element.querySelectorAll(selector);
        }
        else {
            hits = document.querySelectorAll(selector);
        }
        if (!('item' in hits)) {
            // Stapp inn en item-metode for å shimme IE11
            hits.item = (i) => {
                if (i >= 0 && i < hits.length) {
                    return hits[i];
                }
                return null;
            };
        }
        if (!('forEach' in hits)) {
            hits.forEach = (callbackfn, _thisArg) => {
                for (let i = 0; i < hits.length; i++) {
                    const el = hits[i];
                    callbackfn(el, i, hits);
                }
            };
        }
        hits.addClass = (s) => {
            for (let i = 0; i < hits.length; i++) {
                hits[i].classList.add(s);
            }
        };
        hits.removeClass = (s) => {
            for (let i = 0; i < hits.length; i++) {
                hits[i].classList.remove(s);
            }
        };
        return hits;
    }
    static removeClass(node, cname) {
        const cnames = DOMHelper.getClassNames(node);
        delete cnames[cname];
        node.className = DOMHelper.canonicalizeMap(cnames);
    }
    //	var pos = {};
    // Resolve a string identifier to an object
    // ========================================
    static resolveElement(s) {
        if (document.getElementById && document.getElementById(s) != null) {
            return document.getElementById(s);
        }
        else if (document.all && document.all.namedItem(s) != null) {
            const something = document.all.namedItem(s);
            if (something instanceof HTMLElement) {
                return something;
            }
        }
        else if (document.anchors &&
            document.anchors.length &&
            document.anchors.length > 0 &&
            document.anchors[0]) {
            for (let i = 0; i < document.anchors.length; i++) {
                if (document.anchors[i].name === s) {
                    return document.anchors[i];
                }
            }
        }
        return null;
    }
    //	pos.prototype.$VERSION = 1.0;
    // Set the position of an object
    // =============================
    static setPosition(n, left, top) {
        let o = null;
        if (typeof n === 'string') {
            o = this.resolveElement(n);
        }
        else if (n instanceof HTMLElement) {
            o = n;
        }
        if (o == null || !o.style) {
            return false;
        }
        // If the second parameter is an object, it is assumed to be the result of getPosition()
        if (left instanceof DOMPosition) {
            const pos = left;
            left = pos.left;
            top = pos.top;
        }
        o.style.left = left + 'px';
        o.style.top = top + 'px';
        return true;
    } // LegalCSSStylePropertyName | LegalCSSStyleDeclaration
    static style(node, style, extra) {
        if (node instanceof HTMLElement) {
            return DOMHelper._nodeStyle(node, style, extra);
        }
        if (node instanceof Array) {
            for (let i = 0; i < node.length; i++) {
                DOMHelper._nodeStyle(node[i], style, extra);
            }
        }
    }
}
