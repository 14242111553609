import { DOMHelper } from './DOMHelper';
import { Popup } from './Popup';
import { UserAgent } from './UserAgent';
class ComponentData {
    constructor() {
        this._evntcnt = 0;
        this._usecnt = 0;
    }
}
function wrapData(containerElement) {
    if (!containerElement._component_data) {
        containerElement._component_data = new ComponentData();
    }
    return containerElement._component_data;
}
function itemIsSeparator(menuItemEntry) {
    return menuItemEntry.length === 1 && menuItemEntry[0] === '-';
}
function itemIsMenuTitle(menuItemEntry) {
    return (menuItemEntry.length === 1 && typeof menuItemEntry[0] === 'string' && !itemIsSeparator(menuItemEntry));
}
export class bpJsMenu {
    constructor(_menuitems) {
        this._menuitems = _menuitems;
    }
    _show(showEvent, origin) {
        const menuLayerElement = this.getMenuLayer();
        //		l.innerHTML = this.getMenuHtml();
        this.loadMenuHtml(menuLayerElement);
        wrapData(menuLayerElement)._usecnt = wrapData(menuLayerElement)._evntcnt;
        const [xPos, yPos] = this.getMenuXYPos(showEvent, origin);
        if (typeof xPos !== 'undefined') {
            menuLayerElement.style.left = xPos + 'px';
        }
        if (typeof yPos !== 'undefined') {
            menuLayerElement.style.top = yPos + 'px';
        }
        menuLayerElement.style.display = 'block';
    }
    getMenuXYPos(mouseEvent, origin) {
        var _a;
        let xPos = 0;
        let yPos = 0;
        const anchor = (_a = origin !== null && origin !== void 0 ? origin : mouseEvent === null || mouseEvent === void 0 ? void 0 : mouseEvent.target) !== null && _a !== void 0 ? _a : mouseEvent === null || mouseEvent === void 0 ? void 0 : mouseEvent.srcElement;
        if (!anchor) {
            return [xPos, yPos];
        }
        //			y += src.height;
        if (anchor instanceof HTMLElement) {
            const coords = DOMHelper.coords(anchor, true);
            xPos += Math.round(coords.x);
            yPos += Math.round(coords.y);
            const rect = anchor.getBoundingClientRect();
            yPos += Math.round(rect.height);
        }
        return [xPos, yPos];
    }
    clickhandler() {
        const menuLayerElement = this.getMenuLayer();
        menuLayerElement.style.display = 'none;';
    }
    findPos(domNode) {
        const coords = DOMHelper.coords(domNode, true);
        return [Math.round(coords.x), Math.round(coords.y)];
    }
    getDocument() {
        return document;
    }
    createMenuLayer(originDocument) {
        const menuLayerElement = originDocument.createElement('div');
        wrapData(menuLayerElement)._usecnt = 0;
        wrapData(menuLayerElement)._evntcnt = 0;
        menuLayerElement.id = 'popupmenulayer';
        menuLayerElement.style.position = 'absolute';
        menuLayerElement.style.display = 'none';
        menuLayerElement.style.zIndex = '200';
        menuLayerElement.className = 'popup shadow';
        originDocument.body.appendChild(menuLayerElement);
        const userAgent = new UserAgent();
        const clickhandler = (clickEvent) => {
            if (userAgent.isIE && typeof clickEvent !== 'undefined') {
                if (!clickEvent.srcElement) {
                    return;
                }
                if (menuLayerElement &&
                    wrapData(menuLayerElement)._evntcnt++ === wrapData(menuLayerElement)._usecnt) {
                    return;
                }
            }
            const popupMenuLayerElement = originDocument.getElementById('popupmenulayer');
            if (popupMenuLayerElement) {
                popupMenuLayerElement.style.display = 'none';
            }
        };
        originDocument.addEventListener('click', clickhandler, true);
        return menuLayerElement;
    }
    getMenuLayer() {
        const originDocument = this.getDocument();
        let popupMenuLayerElement = originDocument.getElementById('popupmenulayer');
        if (!popupMenuLayerElement) {
            popupMenuLayerElement = this.createMenuLayer(originDocument);
        }
        return popupMenuLayerElement;
    }
    hidePopup(domId) {
        return new Popup().hidePopup(domId);
    }
    loadMenuHtml(domNode) {
        const originDocument = this.getDocument();
        let childElement;
        /**
         * Strip children
         */
        while ((childElement = domNode.firstChild)) {
            domNode.removeChild(childElement);
        }
        const container = originDocument.createElement('div');
        domNode.appendChild(container);
        const table = originDocument.createElement('table');
        DOMHelper.style(table, 'borderCollapse', 'collapse');
        DOMHelper.style(table, 'border', '0');
        container.appendChild(table);
        const tbody = originDocument.createElement('tbody');
        table.appendChild(tbody);
        const items = this._menuitems;
        for (const item of items) {
            const trElement = originDocument.createElement('tr');
            tbody.appendChild(trElement);
            const tdIconElement = originDocument.createElement('td');
            trElement.appendChild(tdIconElement);
            if (itemIsSeparator(item)) {
                tdIconElement.colSpan = 2;
                tdIconElement.innerHTML = '<hr />';
                continue;
            }
            if (itemIsMenuTitle(item)) {
                // her kan vi justere litt
                tdIconElement.colSpan = 2;
                tdIconElement.innerText = item[0];
                tdIconElement.style.fontWeight = 'bold';
                tdIconElement.style.textAlign = 'center';
                tdIconElement.style.padding = '5px';
                continue;
            }
            const tdTextElement = originDocument.createElement('td');
            trElement.appendChild(tdTextElement);
            DOMHelper.style([tdIconElement, tdTextElement], {
                margin: '0',
                padding: '0',
                border: '0',
                textAlign: 'left',
            });
            const userAgent = new UserAgent();
            const attach = (aTagElement) => {
                if (userAgent.isIE > 7.0) {
                    window.dojo.connect(aTagElement, 'onclick', () => {
                        return this.hidePopup('popupmenulayer');
                    });
                }
            };
            const href = item[1];
            let target = '';
            if (typeof item[3] !== 'undefined') {
                target = item[3];
            }
            if (typeof item[2] !== 'undefined') {
                if (item[2]) {
                    const aIconElement = originDocument.createElement('a');
                    if (href) {
                        aIconElement.href = href;
                    }
                    tdIconElement.appendChild(aIconElement);
                    const imgElement = originDocument.createElement('img');
                    imgElement.src = item[2];
                    aIconElement.appendChild(imgElement);
                    if (target) {
                        aIconElement.target = target;
                    }
                    attach(aIconElement);
                }
            }
            const aTextElement = originDocument.createElement('a');
            attach(aTextElement);
            if (href) {
                aTextElement.href = href;
            }
            if (target) {
                aTextElement.target = target;
            }
            aTextElement.innerHTML = item[0];
            tdTextElement.appendChild(aTextElement);
        } // end foreach menypunkt
    }
    show(showEvent, origin) {
        try {
            this._show(showEvent, origin);
        }
        catch (error) {
            console.error('Exception', error);
        }
    }
}
