import { DOMHelper } from './DOMHelper';
export class Popup {
    autoCenter(domElement, keepScroll) {
        const width = domElement.offsetWidth;
        const height = domElement.offsetHeight;
        let windowWidth = 0;
        let windowHeight = 0;
        if (typeof window.innerWidth === 'number') {
            //Non-IE
            windowWidth = window.innerWidth;
            windowHeight = window.innerHeight;
        }
        else if (document.documentElement &&
            (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in standards compliant mode
            windowWidth = document.documentElement.clientWidth;
            windowHeight = document.documentElement.clientHeight;
        }
        else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            windowWidth = document.body.clientWidth;
            windowHeight = document.body.clientHeight;
        }
        let topPos = windowHeight / 2 - height / 2;
        let leftPos = windowWidth / 2 - width / 2;
        if (topPos < 0) {
            topPos =
                document.body.scrollTop ||
                    window.pageYOffset ||
                    (document.body.parentElement ? document.body.parentElement.scrollTop : 0);
        }
        if (leftPos < 0) {
            leftPos = 0;
        }
        if (keepScroll) {
            topPos += window.scrollY;
            leftPos += window.scrollX;
        }
        else {
            scroll(0, 0);
        }
        console.log('Popup.autoCenter(), moving to ' + leftPos + 'x' + topPos, [domElement, leftPos, topPos]);
        domElement.style.top = topPos + 'px';
        domElement.style.left = leftPos + 'px';
    }
    getCoverUpBlankUrl() {
        return '';
    }
    getDocHeight() {
        const rootElement = document.documentElement;
        if (!rootElement) {
            return 0;
        }
        const body = document.body;
        return Math.max(body.scrollHeight, rootElement.scrollHeight, body.offsetHeight, rootElement.offsetHeight, body.clientHeight, rootElement.clientHeight);
    }
    getHeight() {
        // Hentet denne frem fra gamle mainzoot.js, har ikke vært her på årevis
        let myHeight = 0;
        if (typeof window.innerWidth === 'number') {
            //Non-IE
            myHeight = window.innerHeight;
        }
        else if (document.documentElement &&
            (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            myHeight = document.documentElement.clientHeight;
        }
        else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            myHeight = document.body.clientHeight;
        }
        return myHeight;
    }
    getWidth() {
        // Hentet denne frem fra gamle mainzoot.js, har ikke vært her på årevis
        let myWidth = 0;
        if (typeof window.innerWidth === 'number') {
            //Non-IE
            myWidth = window.innerWidth;
        }
        else if (document.documentElement &&
            (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            myWidth = document.documentElement.clientWidth;
        }
        else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            myWidth = document.body.clientWidth;
        }
        return myWidth;
    }
    hideIframe() {
        const behindCoverUp = DOMHelper.byId('magic_bp_floating_panel_cover_up');
        if (behindCoverUp) {
            DOMHelper.style(behindCoverUp, 'display', 'none');
        }
    }
    hidePopup(domId) {
        try {
            const popupElement = document.getElementById(domId);
            if (popupElement) {
                DOMHelper.style(popupElement, 'display', 'none');
            }
            //		i.style.display = 'none';
        }
        catch (exception) {
            /**
             * Der e kleint i IE...
             */
            console.error(exception);
        }
    }
    loadepopup(inElememt) {
        const anchorCoords = DOMHelper.coords(inElememt);
        const element = DOMHelper.query('span.popup', inElememt).item(0);
        if (!(element instanceof HTMLElement)) {
            return;
        }
        const coords = DOMHelper.coords(element);
        const coordsbody = DOMHelper.coords(document.body);
        const rootH = coordsbody.h;
        const rootw = coordsbody.w;
        const coordsX = anchorCoords.x; // X retning
        const coordsY = anchorCoords.y; // Y retning
        const coordsW = coords.w; //Bredde
        const coordsH = coords.h; //Hoyde
        let lastY = coordsY;
        if (coordsY + coordsH > rootH) {
            lastY = coordsY - (20 + coordsH);
        }
        let lastX = coordsX;
        if (coordsX + coordsW > rootw) {
            lastX = coordsX - (coordsX + coordsW - rootw);
        }
        DOMHelper.style(element, {
            left: lastX + 'px',
            top: lastY + 'px',
        });
    }
    moveIframeIntoView(panel) {
        const iframes = document.getElementsByTagName('iframe').length;
        if (!iframes) {
            return;
        }
        const coverUpBlankUrl = this.getCoverUpBlankUrl();
        if (!coverUpBlankUrl) {
            return;
        }
        const coverUpId = 'magic_bp_floating_panel_cover_up';
        let coverUpElement = DOMHelper.byId(coverUpId);
        const border = 0;
        if (!coverUpElement) {
            coverUpElement = document.createElement('iframe');
            coverUpElement.src = coverUpBlankUrl;
            DOMHelper.style(coverUpElement, {
                position: 'absolute',
                left: String(-border),
                top: String(-border),
                zIndex: '-1',
                background: 'white',
            });
        }
        if (coverUpElement !== panel.firstChild) {
            panel.insertBefore(coverUpElement, panel.firstChild);
        }
        const coords = DOMHelper.coords(panel);
        DOMHelper.style(coverUpElement, 'width', coords.w + 2 * border + 'px');
        DOMHelper.style(coverUpElement, 'height', coords.h + 2 * border + 'px');
    }
    setOverlay(overlay) {
        let windowWidth = 0;
        const totalHeight = this.getDocHeight();
        if (typeof window.innerWidth === 'number') {
            //Non-IE
            windowWidth = window.innerWidth;
        }
        else if (document.documentElement && document.documentElement.clientWidth) {
            //IE 6+ in standards compliant mode
            windowWidth = document.documentElement.clientWidth;
        }
        else if (document.body && document.body.clientWidth) {
            //IE 4 compatible
            windowWidth = document.body.clientWidth;
        }
        overlay.style.width = windowWidth + 'px';
        overlay.style.height = totalHeight + 'px';
    }
    showElementAsPopup(domId, clickToClose = false) {
        const popupElement = document.getElementById(domId);
        if (!popupElement) {
            return;
        }
        if (popupElement.style.display === 'block' && popupElement.style.zIndex) {
            return;
        }
        popupElement.style.position = 'absolute';
        popupElement.style.display = 'block';
        let popupXPos = typeof this.lastmousex === 'undefined' ? 300 : this.lastmousex;
        let popupYPos = typeof this.lastmousey === 'undefined' ? 100 : this.lastmousey;
        /*
            if (title != undefined) {
                i.innerHTML = title;
            }
        */
        if (event instanceof MouseEvent) {
            const mouseEvent = event;
            try {
                let lastMouseX;
                let lastMouseY;
                if (mouseEvent.pageX || mouseEvent.pageY) {
                    popupXPos = mouseEvent.pageX;
                    popupYPos = mouseEvent.pageY;
                }
                else if (mouseEvent.clientX || mouseEvent.clientY) {
                    lastMouseX = mouseEvent.clientX;
                    lastMouseY = mouseEvent.clientY;
                    popupXPos = window.scrollX + lastMouseX;
                    popupYPos = window.scrollY + lastMouseY;
                }
                this.lastmousex = lastMouseX;
                this.lastmousey = lastMouseY;
            }
            catch (exception) {
                console.error(exception);
            }
            //		alert(event);
            //	alert('putter den p� '+x+'x'+y);
        }
        popupXPos -= 200;
        popupYPos -= 50;
        if (popupXPos < 0) {
            popupXPos = 0;
        }
        if (popupYPos < 0) {
            popupYPos = 0;
        }
        if (popupXPos) {
            popupElement.style.left = popupXPos + 'px';
        }
        if (popupYPos) {
            popupElement.style.top = popupYPos + 'px';
        }
        try {
            popupElement.style.top = this.getHeight() / 2 - popupElement.offsetHeight / 2 + 'px';
            popupElement.style.left = this.getWidth() / 2 - popupElement.offsetWidth / 2 + 'px';
        }
        catch (error) {
            popupElement.style.top = '100px';
            popupElement.style.left = '100px';
            console.error(error);
        }
        //alert(i.style.top);
        //	silent_notify(lastmousex+' x '+lastmousey + ' - '+window.scrollX + ' x ' +window.scrollY);
        popupElement.style.backgroundColor = 'white';
        //i.style.border = '3px solid #808080';
        popupElement.style.padding = '10px';
        const zIndex = this.getZIndex();
        popupElement.style.zIndex = String(100 + zIndex);
        if (arguments.length > 1 && clickToClose) {
            popupElement.onclick = () => {
                this.hidePopup(domId);
            };
        }
        popupElement.scrollIntoView();
    }
    showElementAsPopupInPosition(domId, clickToClose = false, topPosition, leftPosition, borderWidth) {
        const popupElement = document.getElementById(domId);
        if (!popupElement) {
            return;
        }
        if (popupElement.style.display === 'block') {
            return;
        }
        popupElement.style.position = 'absolute';
        popupElement.style.display = 'block';
        //const x = typeof this.lastmousex === 'undefined' ? 300 : this.lastmousex
        //const y = typeof this.lastmousey === 'undefined' ? 100 : this.lastmousey
        if (arguments.length > 2 && (topPosition || leftPosition || borderWidth !== 3)) {
            try {
                if (!topPosition) {
                    topPosition = 0;
                }
                if (!leftPosition) {
                    leftPosition = 0;
                }
                if (!(borderWidth === '')) {
                    borderWidth = 3;
                }
                popupElement.style.top = topPosition + 'px';
                popupElement.style.left = leftPosition + 'px';
                // popupElement.style.border = borderWidth + 'px solid gray';
                popupElement.style.padding = '0px';
                popupElement.style.background = 'none';
            }
            catch (exception) {
                console.error(exception);
                popupElement.style.top = '100px';
                popupElement.style.left = '100px';
                // popupElement.style.border = '3px solid gray';
                popupElement.style.padding = '10px';
                popupElement.style.backgroundColor = 'white';
            }
        }
        else {
            try {
                popupElement.style.top = this.getHeight() / 2 - popupElement.offsetHeight / 2 + 'px';
                popupElement.style.left = this.getWidth() / 2 - popupElement.offsetWidth / 2 + 'px';
                //i.style.border = '3px solid gray';
                popupElement.style.padding = '10px';
                popupElement.style.backgroundColor = 'white';
            }
            catch (exception) {
                console.error(exception);
                popupElement.style.top = '100px';
                popupElement.style.left = '100px';
                //i.style.border = '3px solid gray';
                popupElement.style.padding = '10px';
                popupElement.style.backgroundColor = 'white';
            }
        }
        //alert(i.style.top);
        //	silent_notify(lastmousex+' x '+lastmousey + ' - '+window.scrollX + ' x ' +window.scrollY);
        //i.style.border = '3px solid gray';
        // i.style.padding = '10px';
        const zIndex = this.getZIndex();
        popupElement.style.zIndex = String(100 + zIndex);
        if (arguments.length > 1 && clickToClose) {
            popupElement.onclick = () => {
                this.hidePopup(domId);
            };
        }
        popupElement.scrollIntoView();
    }
    getZIndex() {
        if (!top._zIndex_count) {
            top._zIndex_count = 0;
        }
        return top._zIndex_count++;
    }
}
