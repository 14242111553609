export class DataStore {
    getAllData() {
        const px = this.getProxy();
        if (px) {
            return px.getAllData();
        }
        throw new Error('Mangler proxy');
    }
    getProxy() {
        return this._proxy;
    }
    setProxy(proxy) {
        this._proxy = proxy;
    }
}
