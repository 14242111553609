import { ExceptionHandler } from './core/ExceptionHandler';
import { DOMHelper } from './dom/DOMHelper';
import { Utils } from './Utils';
export class FieldUtilities {
    _getActParam() {
        return '_actparam' in window ? window._actparam : undefined;
    }
    _getActWrap() {
        return '_actwrap' in window ? window._actwrap : undefined;
    }
    actparam(param) {
        const actionParam = this._getActParam();
        if (!actionParam) {
            return;
        }
        const elements = document.getElementsByName(actionParam);
        let actionParamInputNode = null;
        for (let i = 0; i < elements.length; i++) {
            const node = elements[i];
            if (node && node.tagName.match(/input/i) && node instanceof HTMLInputElement) {
                actionParamInputNode = node;
                break;
            }
        }
        if (typeof param !== 'undefined' &&
            typeof actionParamInputNode !== 'undefined' &&
            actionParamInputNode) {
            actionParamInputNode.value = param;
        }
        else if (typeof actionParamInputNode !== 'undefined' && actionParamInputNode) {
            actionParamInputNode.value = '';
        }
    }
    aksjon(action, param = null) {
        const actionWrapperName = this._getActWrap();
        if (!actionWrapperName) {
            return;
        }
        let elements = document.getElementsByName(actionWrapperName);
        let actionRunInputNode = null;
        let actionInputNode = null;
        for (let i = 0; i < elements.length; i++) {
            const actionNode = elements[i];
            if (actionNode.tagName.match(/input/i) && actionNode instanceof HTMLInputElement) {
                actionInputNode = actionNode;
                break;
            }
        }
        if (param !== null) {
            this.actparam(param);
        }
        const globalWindow = window;
        const runNodeId = '_run' in globalWindow ? globalWindow._run : '';
        elements = document.getElementsByName(runNodeId);
        for (let i = 0; i < elements.length; i++) {
            const runNode = elements[i];
            if (runNode.tagName.match(/input/i) && runNode instanceof HTMLInputElement) {
                actionRunInputNode = runNode;
                break;
            }
        }
        if (actionInputNode) {
            actionInputNode.value = action;
            if (actionRunInputNode) {
                actionRunInputNode.value = '42';
            }
            if (actionInputNode.form) {
                actionInputNode.form.submit();
            }
        }
        return false;
    }
    // [B]pfield[D]ependency[A]bsolute[V]alue
    bdav(felt, reqfeltid, requestedValue, h) {
        const myField = this.jsgf(reqfeltid, felt);
        if (!myField) {
            return;
        }
        let newState = false;
        if (felt.type.match(/checkbox/)) {
            newState = (felt.checked ? 1 : 0) === (requestedValue ? 1 : 0);
        }
        else {
            newState = felt.value === requestedValue;
        }
        if (h) {
            myField.style.display = newState ? 'inline' : 'none';
        }
        else {
            this.setFieldEnabledState(myField, newState);
        }
    }
    bdne(felt, reqfeltid, h) {
        const myField = this.jsgf(reqfeltid, felt);
        if (!myField) {
            return;
        }
        let newState = false;
        if (felt.type.match(/checkbox/)) {
            newState = felt.checked ? true : false;
        }
        else {
            newState = felt.value && felt.value.length ? true : false;
        }
        if (h) {
            myField.style.display = newState ? 'inline' : 'none';
        }
        else {
            this.setFieldEnabledState(myField, newState);
            //		myf.disabled = !s;
        }
    }
    // [B]pfield[D]ependency[V]alue[A]rray
    bdva(felt, reqfeltid, arrstr) {
        const myf = this.jsgf(reqfeltid, felt);
        if (!myf) {
            return;
        }
        const liste = arrstr.split(/,/);
        myf.disabled = !Utils.in_array(felt.value, liste);
    }
    bff(_felt, keyboardEvent) {
        if (typeof keyboardEvent === 'undefined') {
            if (typeof event === 'undefined') {
                return;
            }
            if (event instanceof KeyboardEvent) {
                keyboardEvent = event;
            }
            else {
                return;
            }
        }
        if (keyboardEvent instanceof KeyboardEvent) {
            switch (keyboardEvent.key) {
                case 'Backspace':
                case 'Home':
                case 'End':
                case 'Delete':
                case 'Enter':
                case 'ArrowDown':
                case 'ArrowLeft':
                case 'ArrowRight':
                case 'ArrowUp':
                    return true;
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case '.':
                case ',':
                case '-':
                case '+':
                    return true;
            }
            keyboardEvent.preventDefault();
        }
    }
    // [B]pfield[F]ilter[I]nt
    bfi(_felt, keyboardEvent) {
        if (typeof keyboardEvent === 'undefined') {
            if (typeof event === 'undefined') {
                return;
            }
            if (event instanceof KeyboardEvent) {
                keyboardEvent = event;
            }
            else {
                return;
            }
        }
        if (keyboardEvent instanceof KeyboardEvent) {
            switch (keyboardEvent.key) {
                case 'Backspace':
                case 'Home':
                case 'End':
                case 'Delete':
                case 'Enter':
                case 'ArrowDown':
                case 'ArrowLeft':
                case 'ArrowRight':
                case 'ArrowUp':
                    return true;
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case '-':
                case '+':
                    return true;
            }
        }
        keyboardEvent.preventDefault();
    }
    bpfc(felt, inputEvent, factions) {
        return this.bpFieldChange(felt, inputEvent, factions);
    }
    bpFieldChange(felt, inputEvent, factions) {
        let i;
        let retValue;
        for (i = 0; i < factions.length; i++) {
            retValue = factions[i](felt, inputEvent);
        }
        return retValue;
    }
    bpMoveSelected(fromList, toList, vname, retning, postCallback) {
        const ilist = [];
        const movedElements = [];
        for (let j = 0; j < fromList.options.length; j++) {
            const originalOptElement = fromList.options[j];
            if (!originalOptElement.selected) {
                continue;
            }
            ilist[ilist.length] = j;
            const newOptElement = new Option(originalOptElement.text, originalOptElement.value);
            toList.options[toList.options.length] = newOptElement;
            const optGroupId = originalOptElement.getAttribute('data-bp-optgroup-id');
            if (optGroupId) {
                newOptElement.setAttribute('data-bp-optgroup-id', optGroupId);
                const label = originalOptElement.getAttribute('data-bp-optgroup-label');
                if (label) {
                    newOptElement.setAttribute('data-bp-optgroup-label', label);
                }
                //                opt.setAttribute('data-bp-optgroup-moved', 1);
            }
            const optSequenceId = originalOptElement.getAttribute('data-bp-seq');
            if (optSequenceId) {
                newOptElement.setAttribute('data-bp-seq', optSequenceId);
            }
            movedElements.push(newOptElement);
        }
        for (let j = ilist.length - 1; j >= 0; j--) {
            fromList.options.remove(ilist[j]);
            //			delete fromList.options[ilist[j]]; //  = null;
        }
        vname.value = ',';
        //    var fix_optgroup = null;
        if (parseInt(retning, 10) === 1) {
            for (let j = 0; j < toList.options.length; j++) {
                vname.value += toList.options[j].value + ',';
            }
        }
        else if (parseInt(retning, 10) === 2) {
            for (let j = 0; j < fromList.options.length; j++) {
                vname.value += fromList.options[j].value + ',';
            }
        }
        const fixOptGroup = toList;
        if (movedElements.length) {
            /** fixed sorteringer */
            this.sortOptionChildren(toList);
            const targetOptGroups = toList.querySelectorAll('optgroup');
            for (let index = 0; index < targetOptGroups.length; index++) {
                const optGroup = targetOptGroups[index];
                this.sortOptionChildren(optGroup);
            }
            const optionElements = [];
            for (let toListIdx = 0; toListIdx < toList.options.length; toListIdx++) {
                optionElements.push(toList.options[toListIdx]);
            }
            /** Rydd opp opt-group-tilhørighet i til-lista */
            const optgroups = fixOptGroup.getElementsByTagName('optgroup');
            const firstOptGroup = optgroups.length ? optgroups[0] : null;
            const optGroupMap = {};
            for (let i = 0; i < optgroups.length; i++) {
                const optgroup = optgroups[i];
                const optGroupId = optgroup.getAttribute('data-bp-optgroup-id');
                if (optGroupId) {
                    optGroupMap[optGroupId] = optgroup;
                }
            }
            for (let i = 0; i < movedElements.length; i++) {
                const optElement = movedElements[i];
                const optGroupId = optElement.getAttribute('data-bp-optgroup-id');
                if (!optGroupId) {
                    if (firstOptGroup && firstOptGroup.parentElement === optElement.parentElement) {
                        const optGroupParent = firstOptGroup.parentElement;
                        // Flytt alle uten-optgroup øverst
                        if (optGroupParent) {
                            optGroupParent.insertBefore(optElement, firstOptGroup);
                        }
                    }
                    continue;
                }
                let optGroupElement = optGroupId in optGroupMap ? optGroupMap[optGroupId] : undefined;
                if (!optGroupElement) {
                    optGroupElement = document.createElement('optgroup');
                    optGroupElement.setAttribute('data-bp-optgroup-id', optGroupId);
                    const optGroupLabel = optElement.getAttribute('data-bp-optgroup-label');
                    if (optGroupLabel) {
                        optGroupElement.setAttribute('label', optGroupLabel);
                        optGroupElement.setAttribute('data-bp-optgroup-label', optGroupLabel);
                    }
                    optGroupMap[optGroupId] = optGroupElement;
                    fixOptGroup.appendChild(optGroupElement);
                }
                optGroupElement.appendChild(optElement);
            }
            /** Fjernt tomme optgroups */
            const fraOptGroups = fromList.getElementsByTagName('optgroup');
            for (let i = 0; i < fraOptGroups.length; i++) {
                const optgroup = fraOptGroups[i];
                if (!optgroup.children.length) {
                    if (optgroup.parentElement) {
                        optgroup.parentElement.removeChild(optgroup);
                    }
                }
            }
        }
        if (postCallback) {
            postCallback();
        }
        return false;
    }
    sortOptionChildren(parentElement) {
        let limit;
        let changed = false;
        do {
            const options = [];
            for (const nodeIdx in parentElement.childNodes) {
                const node = parentElement.childNodes[nodeIdx];
                if (node.nodeType !== Node.ELEMENT_NODE) {
                    continue;
                }
                const childNode = node;
                if (childNode.tagName != 'OPTION') {
                    continue;
                }
                options.push(childNode);
            }
            if (limit === undefined) {
                limit = options.length;
            }
            let prevNode = null;
            changed = false;
            for (const i in options) {
                const childNode = options[i];
                if (!prevNode) {
                    prevNode = childNode;
                    continue;
                }
                const currentSeq = childNode.getAttribute('data-bp-seq');
                const prevSequence = prevNode.getAttribute('data-bp-seq');
                if (!currentSeq || !prevSequence) {
                    continue;
                }
                if (parseInt(currentSeq, 10) < parseInt(prevSequence, 10)) {
                    changed = true;
                    parentElement.insertBefore(childNode, prevNode);
                }
                prevNode = childNode;
            }
            if (limit-- < 0) {
                console.error('Fail safe in option sorting', options);
                return;
            }
        } while (changed);
    }
    deleterad(cellContentElement) {
        const cellElement = cellContentElement.parentElement;
        const trElement = cellElement ? cellElement.parentElement : null;
        const tbody = trElement ? trElement.parentElement : null;
        if (!tbody) {
            return;
        }
        const trElementId = trElement ? trElement.getAttribute('id') : null; //  rad.getAttributeNode('id').nodeValue;
        const tbodyChildren = tbody.childNodes;
        const husk = [];
        let i;
        for (i = 0; i < tbodyChildren.length; i++) {
            const trNode = tbodyChildren[i];
            if (trNode instanceof HTMLElement) {
                if (trNode.getAttribute('id') !== trElementId) {
                    continue;
                }
                husk[husk.length] = trNode;
            }
        }
        for (i = 0; i < husk.length; i++) {
            tbody.removeChild(husk[i]);
        }
        if (typeof event !== 'undefined') {
            event.cancelBubble = true;
        }
        return false;
    }
    getAnatropousId(element) {
        const __nfrid = element.name.match(/:\d+:$/);
        let id;
        if (__nfrid) {
            id = element.name.replace(/:\d+:$/, '_anatropous' + __nfrid[0]);
        }
        else {
            id = element.name + '_anatropous';
        }
        return id;
    }
    getCorrectVisibleDisplay(node) {
        return DOMHelper.getCorrectVisibleDisplay(node);
    }
    getKildeRad(tableid, radid) {
        if (radid === 0) {
            const tbodysrc = document.getElementById(tableid + '_src');
            if (tbodysrc instanceof HTMLTableSectionElement) {
                return tbodysrc.rows;
            }
            return null;
        }
        const tableSectionElement = document.getElementById(tableid);
        if (!(tableSectionElement instanceof HTMLTableSectionElement)) {
            return null;
        }
        const tbody = tableSectionElement;
        const rows = [];
        for (let i = 0; i < tbody.rows.length; i++) {
            if (tbody.rows[i].getAttribute('id') === 'rad_' + radid) {
                rows[rows.length] = tbody.rows[i];
            }
        }
        return rows;
    }
    getRowIdForSrcElement(tableId, srcelement) {
        let element = srcelement;
        // Let oppover til vi finner en TR
        while (typeof element !== 'undefined' && element && element.parentElement) {
            const parentElem = element.parentElement;
            // Hvis denne TRen ligger i den tbodyen vi vil den skal ligge i
            if (parentElem.tagName === 'TR' && parentElem.parentElement) {
                const currentTableId = parentElem.parentElement.getAttribute('id');
                if (currentTableId && currentTableId === tableId) {
                    // Og den har en rad_nn id
                    const rawRowId = parentElem.getAttribute('id');
                    if (rawRowId) {
                        const rowIdMatch = rawRowId.match(/rad_(\d+)/);
                        if (rowIdMatch) {
                            return parseInt(rowIdMatch[1], 10);
                        }
                    }
                }
            }
            element = parentElem;
        }
        return 0;
    }
    handleException(_) {
        //		throw new Error("Method not implemented.");
    }
    // [JSG]et[F]ield
    jsgf(feltid, fieldElement = null) {
        if (fieldElement) {
            const listid = fieldElement.name.match(/:\d+:$/);
            if (listid) {
                feltid = feltid.replace(/:\d+:$/, listid[0]);
            }
        }
        const felt = document.getElementById(feltid);
        if (felt instanceof HTMLInputElement ||
            felt instanceof HTMLSelectElement ||
            felt instanceof HTMLTextAreaElement) {
            return felt;
        }
        return null;
    }
    /**
     * kopirad
     */
    kopirad(tableid, srcelement) {
        // Hovedtbody
        const tbody = document.getElementById(tableid);
        if (!(tbody instanceof HTMLTableSectionElement)) {
            return;
        }
        const srcrowid = this.getRowIdForSrcElement(tableid, srcelement);
        if (srcelement && !srcrowid) {
            return false;
        }
        // Kilde-tbody
        const rows = this.getKildeRad(tableid, srcrowid);
        if (!rows) {
            return;
        }
        if (!rows.length) {
            return;
        }
        let row = rows[rows.length - 1];
        //            var row = rows.item(rows.length-1);
        let navn;
        // Finn h�yeste rad-id
        let radid = 0;
        for (let i = 0; i < tbody.rows.length; i++) {
            const tRow = tbody.rows[i];
            const tRowId = tRow.getAttribute('id');
            navn = tRowId ? tRowId : '';
            radid = Utils.max(parseInt(navn.replace(/^rad_/, ''), 10), radid);
        }
        const husk = [];
        // Klon kilderadene
        for (let tableRowIdx = 0; tableRowIdx < rows.length; tableRowIdx++) {
            //row = rows.item(rid);
            row = rows[tableRowIdx];
            const trNode = row.cloneNode(true);
            //var x = tr.children[0].childNodes[0].value;
            const typer = ['input', 'select', 'textarea'];
            // Gå igjønnom feltan å sørg før at de får rett navn...
            for (let i = 0; i < typer.length; i++) {
                const inps = trNode.getElementsByTagName(typer[i]);
                let j;
                for (j = 0; j < inps.length; j++) {
                    const inputElement = inps[j];
                    // OBS!
                    // eslint mein at nån av escapingen i character-classen i regexp nedenfor her e unødvendig.
                    // eslint tar feil. https://gitlab.cerum.no/cerum/cerum/-/issues/2187
                    // "ex_table_704[0]".match(/(^.*[:([])(\d+)([:)]])$/) => null
                    // "ex_table_704[0]".match(/(^.*[:\(\[])(\d+)([:\)\]])$/) => ["ex_table_704[0]", "ex_table_704[", "0", "]" ]
                    // eslint-disable-next-line no-useless-escape
                    const nameMatch = inputElement.name.match(/(^.*[:\(\[])(\d+)([:\)\]])$/);
                    if (nameMatch) {
                        inputElement.name = nameMatch[1] + (radid + 1) + nameMatch[3];
                        inputElement.id = inputElement.name;
                    }
                }
            }
            const a = trNode.getElementsByTagName('a');
            for (let j = 0; j < a.length; j++) {
                if (a[j].name === 'slettrad') {
                    a[j].style.display = 'inline';
                    break;
                }
            }
            trNode.setAttribute('id', 'rad_' + (radid + 1));
            husk[husk.length] = trNode;
        }
        // Legg til nye rader i destination tbodyen
        for (let i = 0; i < husk.length; i++) {
            tbody.appendChild(husk[i]);
        }
        // Pell litt på eventobjektet
        if (typeof event !== 'undefined' && event) {
            event.cancelBubble = true;
        }
        return false;
    }
    nyrad(tableid) {
        this.kopirad(tableid, null);
    }
    setFieldEnabledState(field, state) {
        const fieldId = field.id;
        const labelId = fieldId.replace(/^fl_/, 'lb_');
        field.disabled = !state;
        if (!labelId) {
            return;
        }
        try {
            const label = document.getElementById(labelId);
            if (!label) {
                return;
            }
            let cname = label.className;
            if (state) {
                cname = cname.replace(/disabled/, '');
            }
            else if (!cname.match(/disabled/)) {
                cname += ' disabled';
            }
            label.className = cname;
        }
        catch (error) {
            this.handleException(error);
        }
    }
    /**
     * bear: 2011/06/21 La til en slags horefiks dersom man har samme
     * sjekkboks flere plasser p� samme side.
     */
    togglecheck(inputElement) {
        const id = this.getAnatropousId(inputElement);
        try {
            const formElement = inputElement.form;
            if (!formElement) {
                return false;
            }
            const invertedElement = formElement.elements.namedItem(id);
            if (!invertedElement) {
                return false;
            }
            if (invertedElement instanceof HTMLInputElement) {
                invertedElement.value = inputElement.checked ? '0' : '1';
            }
            else if ('length' in invertedElement) {
                for (let i = 0; i < invertedElement.length; i++) {
                    const singleInvertedElement = invertedElement[i];
                    if (singleInvertedElement instanceof HTMLInputElement) {
                        singleInvertedElement.value = inputElement.checked ? '0' : '1';
                    }
                }
                const duplicateElements = formElement.elements.namedItem(inputElement.id);
                if (duplicateElements && 'length' in duplicateElements) {
                    for (let i = 0; i < duplicateElements.length; i++) {
                        const duplicateInputElement = duplicateElements[i];
                        if (duplicateInputElement instanceof HTMLInputElement) {
                            duplicateInputElement.checked = inputElement.checked;
                        }
                    }
                }
            }
        }
        catch (error) {
            ExceptionHandler.handle(error);
        }
    }
}
/*

bear: 2011/06/21 Begynnelsen p� � lage et $BP-objekt

// fielddepscript.js:function bpMoveSelected(fromList, toList, vname, retning) {
function bpMoveSelected(fromList, to
fielddepscript.js:		function bpFieldChange(felt, ev, factions) {
fielddepscript.js:		function bpfc(felt, ev, factions) {
fielddepscript.js:		function getAnatropousId(element) {
fielddepscript.js:		function togglecheck(o) {
fielddepscript.js:		function max(a, b) {
fielddepscript.js:		function getKildeRad(tableid, radid) {
fielddepscript.js:		function nyrad(x) {
fielddepscript.js:		function getRowIdForSrcElement(tableid, srcelement) {
fielddepscript.js:        function kopirad(tableid, srcelement) {
fielddepscript.js:		function deleterad(o) {
fielddepscript.js:function jsgf(feltid) {
fielddepscript.js:function bdav(felt, reqfeltid, rv, h) {
fielddepscript.js:function in_array(needle, haystack) {
fielddepscript.js:function setFieldEnabledState(field, state) {
fielddepscript.js:function bdva(felt, reqfeltid, arrstr) {
fielddepscript.js:function bfi(felt, ev) {
fielddepscript.js:function bff(felt, ev) {
fielddepscript.js:			function actparam(p) {
fielddepscript.js:            function aksjon(a, p) {
fielddepscript.js:function getCorrectVisibleDisplay(node) {
fielddepscript.js.bak:function bpMoveSelected(fromList, toList, vname, retning) {
fielddepscript.js.bak:		function bpFieldChange(felt, ev, factions) {
fielddepscript.js.bak:		function bpfc(felt, ev, factions) {
fielddepscript.js.bak:		function getAnatropousId(element) {
fielddepscript.js.bak:		function togglecheck(o) {
fielddepscript.js.bak:		function max(a, b) {
fielddepscript.js.bak:		function getKildeRad(tableid, radid) {
fielddepscript.js.bak:		function nyrad(x) {
fielddepscript.js.bak:		function getRowIdForSrcElement(tableid, srcelement) {
fielddepscript.js.bak:        function kopirad(tableid, srcelement) {
fielddepscript.js.bak:		function deleterad(o) {
fielddepscript.js.bak:function jsgf(feltid) {
fielddepscript.js.bak:function bdav(felt, reqfeltid, rv, h) {
fielddepscript.js.bak:function in_array(needle, haystack) {
fielddepscript.js.bak:function setFieldEnabledState(field, state) {
fielddepscript.js.bak:function bdva(felt, reqfeltid, arrstr) {
fielddepscript.js.bak:function bfi(felt, ev) {
fielddepscript.js.bak:function bff(felt, ev) {
fielddepscript.js.bak:			function actparam(p) {
fielddepscript.js.bak:            function aksjon(a, p) {
fielddepscript.js.bak:function getCorrectVisibleDisplay(node) {
*/
