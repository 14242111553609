export class HtmlWidgets {
    CalcCharLeft(felt, interval) {
        const maxLength = parseInt(felt.getAttribute('maxlength') || '', 10);
        let charleft = maxLength;
        if (felt.value.length > maxLength) {
            felt.value = felt.value.substring(0, maxLength);
            charleft = 0;
        }
        else {
            charleft = maxLength - felt.value.length;
        }
        const antall_node_id = 'antalldomnodeid' in felt.dataset ? felt.dataset.antalldomnodeid : null;
        if (!antall_node_id) {
            console.error('Mangler antalldomnodeid', felt);
            return;
        }
        const updateing = document.getElementById(antall_node_id);
        if (!updateing) {
            console.error('Finner ikke node med id:' + antall_node_id, [felt, antall_node_id]);
            return;
        }
        // Den her trengs oversettingsmuligheter
        const prefix = 'Characters left'; // 'Antall tegn igjen';
        let str = prefix + ': ' + charleft;
        if (interval) {
            str += ' / ' + (1 + Math.floor(felt.value.length / interval));
        }
        updateing.innerHTML = str;
    }
}
