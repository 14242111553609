import { Ajax as badphraim_ajax } from './ajax/Ajax';
import { bpFileUploadHandler as badphraim_bpFileUploadHandler } from './bpFileUploadHandler';
import { DataStore as badphraim_data_datastore } from './data/DataStore';
import { bpJsMenu as badphraim_bpJsMenu } from './dom/bpJsMenu';
import { Dom as badphraim_dom } from './dom/Dom';
import { DOMHelper as badphraim_domhelper } from './dom/DOMHelper';
import { HtmlWidgets as bpHtmlWidgets } from './dom/HtmlWidgets';
import { Popup as badphraim_popup } from './dom/Popup';
import { UserAgent as badphraim_useragent } from './dom/UserAgent';
import { FieldUtilities as badphraim_field_utilities } from './FieldUtilities';
export class badphraim {
}
badphraim._available = '';
(function (badphraim) {
    let classes;
    (function (classes) {
        classes.FieldUtilities = badphraim_field_utilities;
        classes.DOMHelper = badphraim_domhelper;
        classes.UserAgent = badphraim_useragent;
        classes.Popup = badphraim_popup;
        classes.Ajax = badphraim_ajax;
        classes.bpFileUploadHandler = badphraim_bpFileUploadHandler;
        classes.bpJsMenu = badphraim_bpJsMenu;
        classes.HtmlWidgets = bpHtmlWidgets;
        let data;
        (function (data) {
            data.DataStore = badphraim_data_datastore;
        })(data = classes.data || (classes.data = {}));
    })(classes = badphraim.classes || (badphraim.classes = {}));
    badphraim.html_widgets = new bpHtmlWidgets();
    badphraim.field_utils = new badphraim_field_utilities();
    badphraim.dom = new badphraim_dom(document);
    badphraim.ajax = new badphraim_ajax(badphraim.dom);
    badphraim.popup = new badphraim_popup();
    function ejs(jsString) {
        let limit = 10;
        while (!jsString.match(/^\/\*%\*\//)) {
            jsString = unescape(jsString);
            if (!limit--) {
                break;
            }
        }
        if (limit && jsString) {
            eval(jsString);
        }
    }
    badphraim.ejs = ejs;
    function initForWindow(window) {
        if (!('badphraim' in window)) {
            window.badphraim = badphraim;
        }
        const localFieldUtils = badphraim.field_utils;
        // Kopier alle funksjonen ut i window. *Sukk*
        for (const i in localFieldUtils) {
            const somePtr = localFieldUtils[i];
            if (somePtr instanceof Function) {
                const funcPtr = somePtr;
                if (!(i in window)) {
                    ((fname, func) => {
                        // eslint-disable-next-line no-constant-condition
                        window[fname] = function () {
                            console.error('Burde vært kalt med badphraim.field_utils.' +
                                fname +
                                '(...) i stedet for ' +
                                fname +
                                '(..)');
                            return func.apply(localFieldUtils, arguments);
                        };
                    })(i, funcPtr);
                }
            }
        }
    }
    badphraim.initForWindow = initForWindow;
})(badphraim || (badphraim = {}));
